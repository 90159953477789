import React from "react";
import {
  CardWrapper,
  CardContainer,
  ImageWrapper,
} from "../CalendarIntegrations/styles";

const FitnessDevicesData = [
  { label: "Fitbit", url: "fitnessEducation/Fitbit_logo.svg" },
  { label: "Google Fit", url: "fitnessEducation/Googlefit.svg" },
  { label: "Strava", url: "fitnessEducation/Strava.svg", width: "105px" },
  { label: "Garmin", url: "fitnessEducation/Garmin.svg" },
  // { label: "HealthKit", url: "HowItWorksV2/HealthKit.png", width: "60px" },
  // { label: "Run Keeper", url: "HowItWorksV2/RunKeeper.png", width: "60px" },
  // { label: "Withings", url: "fitnessEducation/Withings.svg" },
  // {
  //   label: "Apple Watch",
  //   url: "fitnessEducation/Apple_Watch_official_logo.svg",
  // },
];

const FitnessDevicesData1 = [
  { label: "HealthKit", url: "HowItWorksV2/HealthKit.png", width: "60px" },
  { label: "Withings", url: "fitnessEducation/withings.svg" },
  {
    label: "Apple Watch",
    url: "fitnessEducation/Apple_Watch_official_logo.svg",
  },
];
const FitnessDevices = () => (
  <React.Fragment>
    <CardWrapper>
      {FitnessDevicesData.map((data, index) => (
        <CardContainer key={index}>
          <ImageWrapper width={data.width}>
            <img src={`/public/images/${data.url}`} />
          </ImageWrapper>
          <div>
            <p>{data.label}</p>
          </div>
        </CardContainer>
      ))}
    </CardWrapper>

    <div style={{ display: "flex", justifyContent: "center" }}>
      {FitnessDevicesData1.map((data, index) => (
        <CardContainer key={index}>
          <ImageWrapper width={data.width}>
            <img src={`/public/images/${data.url}`} />
          </ImageWrapper>
          <div>
            <p>{data.label}</p>
          </div>
        </CardContainer>
      ))}
    </div>
  </React.Fragment>
);

export default FitnessDevices;
