/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {IntegrationContainer, TextContainer, Text, IntegrationImageWrapper, IntegrationContentContainer, Button, JourneyBox, AlignInitiatives, CommunicationAutomation, ImageLeft, ImageRight} from './styles';

import { ImageUrl } from '../../utils/constants';

import {Top} from '../WhyAmigoCommon';
import Tabs from "../Tabs";
import CalendarIntegrations from "./CalendarIntegrations/index";
import FitnessDevices from "./FitnessDevices";
import ScreensMonitors from "./ScreensMonitors";
import ContactUs from "../AboutPageV2/ContactUs";
import LazyImage from '../common/LazyImage/LazyImage';

// const journeyData = [
//   {heading:"Align Initiatives", description: "We collaborate to combine your company goals and mission with historical data to lay the foundation.", className: 'amigoContainer'},
//   {heading:"Communication & Automation", description: "Automatic notifications, reminders, and emails easily communicate initiatives, events or activities across your organization including remote teams.", className: 'strategyContainer' },
//   {heading:"Connect Teams", description: "A mobile intranet links employee across your entire company. Colleagues feel connected to the team and mission regardless of their location or department.", className:  'implementationContainer'},
//   {heading:"Real Time Data", description: "Metrics are instantly populated to monitor initiatives, address business challenges and propel employee engagement.", className: 'dataContainer'},
//   {heading:"Drive Results", description: "Start with employee experience. Recognize participation and engagement. Then take your program to the next level by creating a culture of wellbeing." , className: 'optimizationContainer'}
// ];

class HowItWorksDashBoard extends Component {

  render() {
    const tabs = [{label: "Calendar Integration", component: <CalendarIntegrations />},
      {label: "Fitness Devices", component: <FitnessDevices />},
      {label: "Screens and Monitors", component: <ScreensMonitors />}
    ];
    const { history } = this.props;
    return (
      <div>
        <Top />
        <JourneyBox>
          <h2>Your Journey With Woliba</h2>
          <AlignInitiatives childNumber={'firstChild'}>
            <ImageLeft>
              <LazyImage src={ImageUrl + '/images/HowItWorksV2/illustration_discussion.svg'} alt={'align-initiatives'} className="image-left"/>
            </ImageLeft>
            <div className={'text-right'}>
              <h3 className={'title'}>Align Initiatives</h3>
              <p className={'description'}>We collaborate to combine your company goals and mission with historical data to lay the foundation.</p>
            </div>
            <LazyImage src={ImageUrl + '/images/HowItWorksV2/Line1.svg'} alt="" className="arrow-image first" />
          </AlignInitiatives>
          <CommunicationAutomation childNumber={'secondChild'}>
            <div className={'text-left'}>
              <h3 className={'title'}>Communication & Automation</h3>
              <p className={'description'}>Automatic notifications, reminders, and emails easily communicate initiatives, events or activities across your organization including remote teams.</p>
            </div>
            <ImageRight>
              <LazyImage src={ImageUrl + '/images/HowItWorksV2/Illustartion_strateguy_proposal.svg'} alt = {'strategy-proposal'} className="image-left"/>
            </ImageRight>
            <LazyImage src={ImageUrl + '/images/HowItWorksV2/Line2.svg'} alt="" className="arrow-image second" />
          </CommunicationAutomation>
          <AlignInitiatives childNumber={'thirdChild'}>
            <ImageLeft>
              <LazyImage src={ImageUrl + '/images/HowItWorksV2/Illustartion_Data_monitoring.svg'} alt={'align-initiatives'} className="image-left"/>
            </ImageLeft>
            <div className={'text-right'}>
              <h3 className={'title'}>Connect Teams</h3>
              <p className={'description'}>A mobile intranet links employee across your entire company. Colleagues feel connected to the team and mission regardless of their location or department.</p>
            </div>
            <LazyImage src={ImageUrl + '/images/HowItWorksV2/Line1.svg'} className="arrow-image third" />
          </AlignInitiatives>
          <CommunicationAutomation childNumber={'fourthChild'}>
            <div className={'text-left'}>
              <h3 className={'title'}>Real Time Data</h3>
              <p className={'description'}>Metrics are instantly populated to monitor initiatives, address business challenges and propel employee engagement.</p>
            </div>
            <ImageRight>
              <LazyImage src={ImageUrl + '/images/HowItWorksV2/Illustartion_Data_monitoring.svg'} alt = {'strategy-proposal'} className="image-left"/>
            </ImageRight>
            <LazyImage src={ImageUrl + '/images/HowItWorksV2/Line2.svg'} className="arrow-image fourth" />
          </CommunicationAutomation>
          <AlignInitiatives childNumber={'fifthChild'}>
            <ImageLeft>
              <LazyImage src={ImageUrl + '/images/HowItWorksV2/Illustartion_optimization.svg'} alt={'align-initiatives'} className="image-left"/>
            </ImageLeft>
            <div className={'text-right'}>
              <h3 className={'title'}>Drive Results</h3>
              <p className={'description'}>Start with employee experience. Recognize participation and engagement. Then take your program to the next level by creating a culture of wellbeing.</p>
            </div>
          </AlignInitiatives>
          {/*<div className="journeyImageContainer">*/}
          {/*<picture>*/}
          {/*<source srcSet="/images/HowItWorksV2/Journey-bg.png"/>*/}
          {/*<img src="/images/HowItWorksV2/Journey-bg.png"/>*/}
          {/*</picture>*/}
          {/*{*/}
          {/*journeyData.map((item, index) => (*/}
          {/*<JourneyTextContainer className={item.className} key={index}>*/}
          {/*<h1>{item.heading}</h1>*/}
          {/*<p>{item.description}</p>*/}
          {/*</JourneyTextContainer>*/}
          {/*))*/}
          {/*}*/}
          {/*</div>*/}
        </JourneyBox>
        <IntegrationContainer>
          <TextContainer>
            <Text>
              <h2>Intuitive Integrations</h2>
              <p>
                Now tools you use everyday are united
              </p>
              <div className= "button-wrapper">
                <Button
                  fontSize="13px"
                  bgColor="#fff"
                  Align={1}
                  noneFloat={1}
                  capitalize={1}
                  height="40px"
                  width="184px"
                  textColor="#0273b9"
                  onClick={()=>history.push('/contact')}
                >
                  BOOK A DEMO
                </Button>
              </div>
            </Text>
          </TextContainer>
          <IntegrationImageWrapper>
            <LazyImage src={`${ImageUrl}/images/HowItWorksV2/integration_icon.png`} alt="integrationIcon" />
          </IntegrationImageWrapper>
        </IntegrationContainer>
        <IntegrationContentContainer>
          <Tabs
            tabs={tabs}
            history={this.props.history}
          />
        </IntegrationContentContainer>
        <ContactUs
          history={history}
        />
      </div>
    );
  }
}

HowItWorksDashBoard.propTypes = {
  history: PropTypes.object
};

export default HowItWorksDashBoard;