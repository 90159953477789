import styled from 'styled-components';
const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 30px 0 0 0;
    display: inline-block;
    
    @media (min-width: 320px) and (max-width: 481px) {
        padding: 30px;
    }
`;

const CardContainer = styled.div`
     float: left;
     padding: 20px ;
     margin: 20px 10px;
     width:calc(100% / 4 - 20px );
     height:  ${({height}) => height ? height : '200px'};
     background-color: #fff;
     border-radius: 10px;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     align-items: center;
      
     & > div:nth-child(2){
        display: block;
        text-align: center;
        padding: 12px;
        width: 100%;
        
     }
     
     &> div > p:nth-child(1) {
        font-family: ${MediumFont};
        word-wrap: break-word;
        margin-bottom: 10px;
     }
     
     & > div > p:nth-child(2) {
        text-align: center;
        font-family: ${BoldFont};
        color: #0273b9
        margin: 0;
     }
     
     @media (min-width: 700px) and (max-width: 1024px) {
        width:calc(100% / 3 - 20px );
     }
     
     @media (min-width: 481px) and (max-width: 699px) {
        width:calc(100% / 2 - 20px );
     }
     
     @media (min-width: 320px) and (max-width: 481px) {
        width: 100%
        margin: 20px 0;
     }
}
`;

const ImageWrapper = styled.div`
    position: relative;
    width: ${({width}) => width ? width : '142px'};
    & > img  {
        width: 100%
    }
`;


export {CardWrapper, CardContainer, ImageWrapper}