import styled from 'styled-components';
import { UserButton } from '../EngagementSection/styles';

const ActionDashBoard = styled.div`
  width: 100%;
  height: ${({ height }) => height ? height : '400px' };
  height: ${({ onBoard }) => onBoard && '100%'};
  display: flex;
  margin: 40px 0px 25px;
  align-items: ${({flex}) => flex && 'center'};
  .image {
    float: left;
    width: 100%;
    height: 100%;
    text-align: right;
    > img {
      width: ${({flex}) => flex && '100%'};
      height: ${({flex}) => flex && '100%' };
      @media(max-width: 1200px) {
        width: 100%;
        height: 100%;
      }
    }
  }
  .buttonWrapper {
    text-align: center;
  }
  .content {
    font-size: 52px;
    line-height: 56px;
    color: #333333;
    font-family: "Proxima-Nova-Bold";
  }
  >img {
    width: auto;
  }

  @media(max-width: 1200px) {
    height: ${({ height }) => height ? '340px' : '380px' };
    height: ${({ onBoard }) => onBoard && '100%'};
    margin-bottom: 0px;
    >img {
      width: 50%;
    }
  }

  @media (max-width: 1000px) {
    height: 280px;  
    height: ${({ onBoard }) => onBoard && '100%'};
    .content {
      font-size: 36px;
      line-height: 40px;
      color: #333333;
      font-family: "Proxima-Nova-Bold";
    }
    
  } 

  @media(max-width: 700px) {
    display: block;
    text-align: center;
    height: 100%;
    margin: ${({ noMargin }) => noMargin && '0px'};
    .image {
      margin-top: 25px;
    }
    > div {
      width: 100%;
      float: left;
      > div {
        width: 100%;
      }
    }
    .content {
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }
    .buttonWrapper {
      width: 100%;
      display: ${({ bottom }) => bottom ? 'block' : 'flex'};
      float: ${({ bottom }) => bottom && 'left' };
      justify-content: ${({ bottom }) => !bottom && 'center'};
    }
    >img {
      height: 200px;
      margin-top: 30px;
      width: auto;
    }
  }
  @media(max-width: 330px) {
    >img {
      margin-left: calc((100% - 290px) / 2);
      width: 290px;
    }
  }
`;

const FlexBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  .integration {
    .body {
      > p {
        margin: 0 0 17px;
      }
    }
  }
`;

const Button = styled(UserButton)`
  float: ${({ noneFloat }) => noneFloat && 'none'};
  text-transform: capitalize;
  @media(min-width: 1201px) {
    margin-left: 20px;
  }
  @media(max-width: 1200px) {
    margin-left: 0px;
  }
  @media(max-width: 600px) {
    width: 200px;
    height: 50px;
    font-size: 20px;
    margin-left: 20px;
  }
  @media(max-width: 500px) {
    margin-left: 0px;
  }
`;

const TabHeader = styled.div`
  margin: ${({ bool }) => bool ? '30px 0px 0px' : '30px 0px'};
  width: 100%;
  height: 75px;
  background-color: #159fca;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 34px;
  color: #ffffff;
  font-family: "Proxima-Nova-Bold";
  padding: 0px 25px;
  cursor: ${({ cursor }) => cursor && 'pointer'};
  @media(max-width: 600px) {
    text-align: center;
  }
`;

const WhyAmigoDashboard = styled.div`
  padding: ${({ padding }) => padding && '20px 0px'};
  margin: 0px;
  .flex {
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 100%;
  }
  .flexBox {
    align-items: center;
  }

  .body {
    font-size: 30px;
    line-height: 35px;
    color: #8b8b8b;
    font-family: Proxima-Nova-Regular;
    margin-top: 10px;
    width: ${({ participation }) => participation && '50%'};
    @media(max-width: 1200px) {
      width: 100%;
      font-size: 24px;
    }
    @media(max-width: 600px) {
      font-size: 18px;
      text-align: center;
      line-height: 20px;
    }
  }
  .headerText {
    font-size: 60px;
    color: #333333;
    font-family: "Proxima-Nova-Bold";
    
    @media (max-width: 1000px) {
      font-size: 40px;
    }

    @media(max-width: 600px) {
      font-size: 34px;
      text-align: center;
    }
  }
`;

const AmigoCard = styled.div`
  width: calc((100% / 3) - 25px);
  display: inline-block;
  padding: ${({ noPadding }) => noPadding ? '70px 0px 20px' : '70px 0px'};
  text-align: ${({ communication }) => communication && 'center'};
  > img {
    height: ${({ communication }) => !communication && '85px'};
    max-width: ${({ communication }) => !communication && '100px'};  
    height: ${({ onboard }) => onboard && '130px'};
    max-width: ${({ onboard }) => onboard && '140px'};  
  }
  .name {
    font-size: 33px;
    color: #25a6ce;
    font-family: "Proxima-Nova-Bold";
    margin: ${({ communication, onboard }) => communication ? onboard ? "30px 0px 30px" : "10px 0px 30px" : "10px 0px"};
  }
  .description {
    font-size: ${({ communication, onboard }) => communication ? onboard ? '18px' : '20px' : '18px'};
    line-height: 24px;
    color: #8b8b8b;
    font-family: "Proxima-Nova-Bold";
    margin: ${({ communication }) => communication && "30px 0px 0px"};
  }

  @media(max-width: 1200px) {
    width: ${({ communication }) => communication && 'calc((100% / 3) - 5px)'};
  }

  @media(max-width:1000px) {
    width: calc((100% / 3) - 20px);
    padding: 50px 0px 30px;
    .name {
      font-size: 24px;
    }
    .description { 
      font-size: 16px;
    }
  }

  @media(max-width: 700px) {
    width: 100%;
    text-align: center;
    padding: 25px 0px;
  }
`;

const BorderLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e2e0e0;
  float: left;
  margin-bottom: 40px;
`;

const CommunicationDashBoard = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f3fbff;
  border-bottom: 1px solid #deebf2;
  float: left;
  padding-top: 30px;
  margin: ${({ margin }) => margin && '50px 0px 40px'};
`;

const DemoMargin = styled.div`
  float: left;
  margin-top: 40px;
  width: 100%;
  height: ${({height}) => height ? height : '340px'}; 
  @media(max-width: 600px) {
    height: 200px;
  }

  `;

const DemoMarginNew = styled.div`
  float: left;
  margin-top: 25px;
  width: 100%;
  height: ${({height}) => height ? height : '340px'}; 
  @media(max-width: 600px) {
    height: 200px;
  }

`;

export { DemoMargin, CommunicationDashBoard, BorderLine, AmigoCard, ActionDashBoard, FlexBox, Button, TabHeader, WhyAmigoDashboard, DemoMarginNew }