import React from 'react';
import {CardWrapper, CardContainer, ImageWrapper} from "../CalendarIntegrations/styles";

const ScreensMonitorsData = [{label:"Easily access Woliba's Admin or Employee pages at your desk on any browser", url:"GaugeMeter.png", width: "90px", height: "315px"}, {label:"If you're an Android user, take WOLIBA wherever you go with our Android app.", url: "GooglePlay.png", height: "315px"}, {label:"If you have an iPhone, download our iPhone app so you can recognize your team on the go!", url:"AppStore.png", width: "105px", height: "315px"}, {label:"Watch the videos and read the articles you love on the device you love wherever you are", url:"ApplePhone.png", width:"50px", height: "315px"}];
const ScreenMonitors = () => (
  <CardWrapper>
    {
      ScreensMonitorsData.map((data, index) => (
        <CardContainer key={index} height={data.height}>
          <ImageWrapper width={data.width}>
            <img src={`/public/images/HowItWorksV2/${data.url}`}/>
          </ImageWrapper>
          <div>
            <p>{data.label}</p>
          </div>
        </CardContainer>
      )
      )
    }

  </CardWrapper>
);

export default ScreenMonitors;