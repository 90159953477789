import React from 'react';
import {CardWrapper, CardContainer, ImageWrapper} from "./styles";
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';

const CalendarIntegrationData = [{label: "Google", url: "GoogleCalendar.png", width: "60px"}, {label: "iCalendar", url: "Icalendar.png", width: "60px"}, {label: "Outlook", url: "Outlook.png", width: "60px"}, {label: "Yahoo", url: "Yahoo.png", width: "124px"}];
const CalendarIntegrations = () => (
  <CardWrapper>
    {
      CalendarIntegrationData.map((data, index) => (
        <CardContainer key={index}>
          <ImageWrapper width={data.width}>
            <LazyImage src={`${ImageUrl}/images/HowItWorksV2/${data.url}`}/>
          </ImageWrapper>
          <div>
            <p>{data.label}</p>
          </div>
        </CardContainer>
      )
      )
    }

  </CardWrapper>
);

export default CalendarIntegrations;