import React from "react";
import PropTypes from "prop-types";

import { ChartWrapper, BarWrapper, BarName, BarContainer, FilledBar, BarLabel } from "./styles";

const HorizontalBarGraph = (props) => {
  const { data } = props;
  return (
    <ChartWrapper>
      {
        data.map((item, index) => (
          <BarWrapper key={index}>
            {item.name ? <BarName>{item.name}</BarName> : ""}
            <BarContainer backgroundFill={item.backgroundFill}>
              <FilledBar backgroundColor={item.backgroundColor} width={item.width}>
                {item.width > 6 ? <BarLabel>{item.value}</BarLabel> : null}
              </FilledBar>
            </BarContainer>
          </BarWrapper>
        ))
      }
    </ChartWrapper>
  );
}

HorizontalBarGraph.propTypes = {
  data: PropTypes.array
};

export default HorizontalBarGraph;