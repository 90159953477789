import styled from "styled-components";

const BoldFont = 'Rubik-Medium';

const ChartWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
`;

const BarWrapper = styled.div`
  width: 100%;
  margin: 20px 0 0;

  &:first-child {
    margin: 0;
  }
`;

const BarName = styled.p`
  font-size: 14px;
  color: #282C37;
  letter-spacing: 0;
  margin: 0 0 10px;
  font-family: ${BoldFont};
`;

const BarContainer = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background-color: ${({backgroundFill}) => backgroundFill ? backgroundFill : "rgba(86, 192, 36, 0.3)"};
`;

const FilledBar = styled.div`
  height: 20px;
  border-radius: 10px;
  width: ${({width}) => width ? `${width}%` : "100%"};
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : "#000"};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 6px;
`;

const BarLabel = styled.span`
  font-size: 9px;
  color: #FFFFFF;
  letter-spacing: 0;
  //padding: 0 10px;
  font-family: ${BoldFont};
`;

export { ChartWrapper, BarWrapper, BarName, BarContainer, FilledBar, BarLabel };